.buyers {
  font-size: 16px;
  margin-bottom: 50px;
}
.num {
  font-size: 60px;
  color: red;
  padding-right: 20px;
}
.list-item h4 {
  font-size: 32px;
  color: rgb(162, 162, 162);
  text-transform: capitalize;
}
.list-item {
  margin-bottom: 50px;
}
.list-item p {
  line-height: 1.6;
  padding-left: 20px;
}
.list-item div {
  border-left: 1px red solid;
  margin-left: 10px;
}
