/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 15, 2021 */
/* font-family:  */
/* font-family: "banks_miles_single_lineRg" !important; */
/* font-family: "bodoni_72ptmedium_italic" !important; */
/* font-family: "bodoni_72ptmedium" !important; */
/* font-family: "bodoni_72ptfatface_italic" !important; */
/* font-family: "bodoni_72ptfatface" !important; */
/* font-family: "bodoni_72ptitalic" !important; */
/* font-family: "bodoni_72ptregular" !important; */
/* font-family: "bodoni_72ptbold_italic" !important; */
/* font-family: "bodoni_72ptbold" !important; */
/* font-family: "bodoni_06ptmedium_italic" !important; */
/* font-family: "bodoni_06ptmedium" !important; */
/* font-family: "bodoni_06ptfatface_italic" !important; */
/* font-family: "bodoni_06ptfatface" !important; */
/* font-family: "bodoni_06ptitalic" !important; */
/* font-family: "bodoni_06ptregular" !important; */
/* font-family: "bodoni_06ptbold_italic" !important; */
/* font-family: "bodoni_06ptbold" !important; */

.playfair {
  font-family: "playfair";
}
.lato {
  font-family: "lato-regular";
}

@font-face {
  font-family: "playfair";
  src: url("./fonts/PlayfairDisplay-VariableFont_wght.ttf") format("woff2");
}

@font-face {
  font-family: "lato-regular";
  src: url("./fonts/Lato-Regular.ttf") format("woff2");
}
