.spacer {
  height: 5000px;
  background-color: whitesmoke;
}

.landing-hero {
  height: 80vh;
  background-position: left center;
  background-image: url("../images/black-ink.jpg");
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  padding: 0;
  overflow: hidden;
}
.landing-hero-filter {
  background: linear-gradient(-120deg, rgba(255, 0, 0, 0.112), #000000b3);
  width: 100%;
  height: 100%;
}
.landing-hero-header {
  font-size: 11vw;
  position: absolute;
  color: rgba(0, 0, 0, 0.2);
  line-height: 1.8;
}

.landing-hero-logo {
  position: relative;
  top: 50%;
  left: 50%;
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
  width: 400px;
  transform: translate(-50%, -50%);
}

.landing-property {
  margin: 50px 0;
  text-align: center;
  min-height: 280px;
}
.landing-property i {
  font-size: 100px;
  opacity: 0.2;
  transition-duration: 0.5s;
}
.landing-property i:hover {
  opacity: 1;
  cursor: pointer;
}
.landing-property h3 {
  font-size: 40px;
  opacity: 0;
  margin-top: 20px;
  color: rgb(196, 0, 0);
}
.show {
  opacity: 1 !important;
  transition: opacity;
  transition-duration: 0.5s;
}

.slide-out {
  width: 0;
  transition: width;
  transition-duration: 0.5s;
  overflow: hidden;
  height: 0;
}
.slide-in {
  width: 100%;
  height: 100%;
  transition-duration: 0.5s;
  overflow: hidden;
}
.property-type-content ul {
  display: table;
  margin: 0 auto;
  font-size: 20px;
}
.property-type-content li {
  white-space: nowrap;
}

.property-type-content li:nth-child(even) {
  color: red;
}

.working {
  // background-color: red;
  padding-top: 100px;
  padding-bottom: 100px;
}
.working h1 {
  font-size: 42px;
  color: rgb(177, 177, 177);
  margin-bottom: 20px;
}
.working p {
  font-size: 18px;
  line-height: 1.8;
  border-left: 1px solid red;
  padding-left: 20px;
  margin-bottom: 50px;
}

// Rainbow
@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

.rainbow {
  height: 5px;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: 100px;
    width: 2000px;
    height: 1000px;
    background-color: black;
    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-image: linear-gradient(
        rgba(255, 255, 255, 1),
        rgba(0, 0, 0, 0.2),
        rgba(255, 0, 0, 0.8)
      ),
      linear-gradient(white, rgba(0, 0, 0, 0.2), rgba(255, 0, 0, 0.8)),
      linear-gradient(rgba(255, 0, 0, 0.8), rgba(0, 0, 0, 0.2), white),
      linear-gradient(rgba(255, 0, 0, 0.8), rgba(0, 0, 0, 0.2), white);
    animation: rotate 12s linear infinite;
  }
}
