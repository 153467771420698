.nav-button {
  font-size: 32px;
  position: fixed;
  right: 30px;
  top: 20px;
  z-index: 999;
}
.nav-window-left {
  position: fixed;
  z-index: 998;
  left: 0;
  height: 100vh;
  transition-property: width;
  transition-duration: 0.6s;
  background-position: left center;
  background-image: url("../images/spill.jpg");
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}
.nav-window-right {
  position: fixed;
  z-index: 998;
  right: 0;
  height: 100vh;
  transition-property: width;
  transition-duration: 0.6s;
  background-color: whitesmoke;
  text-align: center;
}
.grey-overlay {
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    -45deg,
    rgba(166, 6, 6, 0.214) 10%,
    rgba(0, 0, 0, 0.947) 90%
  );
}
.cta-header {
  color: rgba(255, 255, 255, 0.297);
  padding: 7px 0;
  font-size: 90px;
  font-family: "playfair";
  text-transform: capitalize;
  margin-top: 33vh;
  margin-left: 20%;
  margin-right: 20px;
  /* margin-bottom: 10px; */
  transform: translate(0px, 10px);
  line-height: 0.6;
  font-weight: bolder;
}
.cta-content {
  z-index: 10;
  color: white;

  margin-left: 20%;
  margin-right: 20%;
  margin-bottom: 30px;
  font-size: 18px;
  font-family: "lato-regular";
}
.cta-button {
  margin-left: 20%;
  margin-top: 400px;
  color: white;
  border: white 2px solid;
  padding: 10px 20px;
  transition-duration: 0.3s;
  font-size: 20px;
  font-family: "playfair";
  font-family: "lato-regular";
  text-transform: uppercase;
}
.cta-button:hover {
  text-decoration: none;
  color: grey;
  border: grey 2px solid;
}

.menu-open {
  width: 50%;
}
.menu-closed {
  width: 0%;
}

.nav-window-content {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.menu-open .nav-window-content {
  transition-property: height;
  transition-delay: 0.6s;
  transition-duration: 0.4s;
  height: 100%;
}
.menu-closed .nav-window-content {
  height: 0px;
}

.nav-link {
  color: black;
  font-size: 5vh;
  margin: 4vh 0;
  font-family: "playfair";
  text-transform: capitalize;
}
.nav-link:hover {
  color: grey;
}
.nav-link:first-child {
  margin-top: 15vh;
}

@media only screen and (max-width: 800px) {
  .cta-header {
    margin-top: 20vh !important;
  }
}

@media only screen and (max-width: 600px) {
  .nav-window-left {
    display: none;
  }
  .menu-open {
    width: 100%;
  }
}
