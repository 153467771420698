.contact {
  min-height: 100vh;
  margin-bottom: 50px;
}
.contact-decoration {
  position: absolute;
  right: -180px;
  width: 300px;
  height: 120vh;
  z-index: -1;
  transform: rotate(15deg);
  background-position: center center;
  background-image: url("../images/spill2.jpg");
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}
.contact-hidden {
  height: 0px;
  overflow: hidden;
}

.contact-header {
  color: rgba(169, 169, 169, 0.18);
  font-size: 170px;
  transform: translate(0, 90px);
  margin-left: 50px;
}
.contact-text {
  font-size: 30px;
  margin: 0 20px;
  font-weight: bolder;
  margin-bottom: 30px;
  text-align: justify;
}
/* .ui.toggle.checkbox input ~ .coloring:after {
  background: rgb(142, 142, 142) !important;
} */
/* .ui.toggle.checkbox input:checked ~ .coloring:before {
  background: rgb(0, 0, 0) !important;
}

.ui.toggle.checkbox input:focus:checked ~ .label:before {
  background-color: rgb(0, 0, 0) !important;
} */

.ui.toggle.checkbox input ~ .coloring.error:before {
  background: #ff0033 !important;
}

.msg-sent-header {
  font-size: 50px;
  margin-top: 75px !important;
  color: rgba(169, 169, 169, 0.817);
}
.contact-button {
  font-size: 16px;
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0);
  color: red;
  border: 1px red solid;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 10px;
  transition-duration: 0.5s;
}
.contact-button:hover {
  color: grey;
  border: 1px grey solid;
}

.ui.form textarea,
.ui.form input,
.ui.selection.dropdown {
  border-radius: 0 !important;
}
@media only screen and (max-width: 1000px) {
  .contact-header {
    font-size: 110px;
    margin-left: 20px;
    transform: translate(0, 60px);
  }
  .contact-text {
    font-size: 20px;
    margin: 0 10px;
    margin-bottom: 30px;
    text-align: justify;
  }
}
@media only screen and (max-width: 550px) {
  .contact {
    margin-bottom: 50px;
  }
  .contact-header {
    font-size: 70px;
    margin-left: 20px;
    transform: translate(0, 40px);
  }
  .contact-text {
    font-size: 16px;
    margin: 0 10px;
    text-align: justify;
    margin-bottom: 30px;
  }
}
