.picture-col {
  padding-left: 0;
  padding-right: 0;
}
.picture-carousel {
  /* height: 60vh; */
  /* width: 100%; */
  overflow: hidden;
}
.carousel {
  /* width: 100%; */
  /* height: 100%; */
  /* margin: auto; */
}
/* .pictures-left {
  height: 60vh;
  background-position: left center;
  background-image: url("../images/18.jpg");
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  padding: 0;
  overflow: hidden;
  margin: 5px;

}
.pictures-right {
  height: 60vh;
  background-position: left center;
  background-image: url("../images/2.jpg");
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  margin: 5px;
  overflow: hidden;
} */
