.info {
  margin-bottom: 50px;
}
.info-pic {
  height: 50vh;
  background-position: center center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  padding: 0;
  overflow: hidden;
  margin: 5px;
}

.info-content {
  font-size: 16px;
  /* margin: 5px; */
}
.info-content h4 {
  font-size: 32px;
  color: rgb(160, 160, 160);
}
.info-content div {
  height: auto;
  text-align: center;
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 16px;
  margin: 0 50px;
}
